// Type checking Actions
export const InitStoreActions = {
    SET_INIT: '[INIT] Init App State',
    SET_INIT_SUCCESS: '[INIT] Init App State SUCCESS',
    INIT_ERROR: '[INIT] Init App State ERROR'
};

// Exporting the interfaces to use later in reducers and action creators
export interface ISetInit {
    type: typeof InitStoreActions.SET_INIT;
}

export interface ISetInitSuccess {
    type: typeof InitStoreActions.SET_INIT_SUCCESS;
    payload: any
}

export interface IInitError {
    type: typeof InitStoreActions.INIT_ERROR;
    error: any
}

//TypeScript Union Types that expressing all possible actions
export type InitActionUnion = ISetInit | ISetInitSuccess | IInitError;
