import {HomeActions} from "./home.actions";

export interface HomeState {
    isHome: boolean;
}

export const InitialHomeState = {
    isHome: false
};

export function homeReducer(state = InitialHomeState, action: any): HomeState {
    switch (action.type) {
        case HomeActions.TOGGLE_IS_HOME: {
            return {
                ...state,
                isHome: action.value
            }
        }

        default:
            return state;
    }

}