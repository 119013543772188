import React from 'react';

const Loading: React.FC = () => {
    return (
        <div id="init-loading" style={{display: 'flex'}}>
            <span className="lds-dual-ring"/>
        </div>
    );
};

export default Loading;