export const leftNav = {
    mainnav: [
        {
            id: 1,
            name: 'Live In-Play',
            icon: 'live_tv',
            slug: 'live-in-play',
            items: [
                {
                    id: 1,
                    name: 'Soccer',
                    icon: 'sports_soccer',
                    slug: 'soccer'
                },
                {
                    id: 2,
                    name: 'Tennis',
                    icon: 'sports_tennis',
                    slug: 'tennis'
                },
                {
                    id: 3,
                    name: 'Handball',
                    icon: 'sports_handball',
                    slug: 'handball'
                },
                {
                    id: 4,
                    name: 'Basketball',
                    icon: 'sports_basketball',
                    slug: 'basketball'
                },
                {
                    id: 5,
                    name: 'Ice Hockey',
                    icon: 'sports_hockey',
                    slug: 'hockey'
                }
            ]
        },
        {
            id: 2,
            name: 'In-Play Buzz',
            icon: 'live_tv',
            slug: 'in-play-buzz',
            items: [
                {
                    id: 1,
                    name: 'Item 1',
                    icon: 'sports_tennis',
                    slug: ''
                },
                {
                    id: 2,
                    name: 'Item 2',
                    icon: 'sports_tennis',
                    slug: ''
                },
                {
                    id: 3,
                    name: 'Item 3',
                    icon: 'sports_tennis',
                    slug: ''
                },
                {
                    id: 4,
                    name: 'Item 4',
                    icon: 'sports_tennis',
                    slug: ''
                }
            ]
        },
        {
            id: 3,
            name: 'Promotions',
            icon: 'sports_tennis',
            slug: 'promos',
            items: [
                {
                    id: 1,
                    name: 'Promo Item 1',
                    icon: 'sports_tennis',
                    slug: ''
                },
                {
                    id: 2,
                    name: 'Promo Item 2',
                    icon: 'sports_tennis',
                    slug: ''
                },
                {
                    id: 3,
                    name: 'Promo Item 3',
                    icon: 'sports_tennis',
                    slug: ''
                },
                {
                    id: 4,
                    name: 'Promo Item 4',
                    icon: 'sports_tennis',
                    slug: ''
                }
            ]
        },
    ],
    subCategories: [
        {
            id: 1,
            name: 'A-Z Sports',
            icon: '',
            items: [
                {
                    id: 1,
                    name: 'Soccer',
                    icon: 'sports_soccer',
                    slug: 'soccer',
                    items: [
                        {
                            id: 1,
                            name: 'English Premier League',
                            icon: '',
                            slug: 'en_prem_league',
                            cat: 'Popular Leagues'
                        },
                        {
                            id: 2,
                            name: 'English Championship',
                            icon: '',
                            slug: 'en_championship',
                            cat: 'Popular Leagues'
                        },
                        {
                            id: 3,
                            name: 'UEFA Champions League',
                            icon: '',
                            slug: 'uefa_championship',
                            cat: 'Popular Leagues'
                        },
                        {
                            id: 4,
                            name: 'Spain - La Liga',
                            icon: '',
                            slug: 'sp_laLiga',
                            cat: 'Popular Countries'
                        },
                        {
                            id: 5,
                            name: 'Englanda',
                            icon: '',
                            slug: 'england',
                            cat: 'Popular Countries'
                        },
                        {
                            id: 6,
                            name: 'Algeria',
                            icon: '',
                            slug: 'algeria',
                            cat: 'All'
                        },
                    ]
                },
                {
                    id: 2,
                    name: 'American Football',
                    icon: 'sports_football',
                    slug: 'am_football'
                },
                {
                    id: 3,
                    name: 'Australian Football',
                    icon: 'sports_soccer',
                    slug: 'au_football'
                },
                {
                    id: 4,
                    name: 'Basketball',
                    icon: 'sports_basketball',
                    slug: 'basketball'
                },
                {
                    id: 5,
                    name: 'Ice Hockey',
                    icon: 'sports_hockey',
                    slug: 'hockey'
                },
                {
                    id: 6,
                    name: 'Baseball',
                    icon: 'sports_baseball',
                    slug: 'baseball'
                },
                {
                    id: 7,
                    name: 'Boxing/ MMA',
                    icon: 'sports_mma',
                    slug: 'boxing_mma'
                },
                {
                    id: 8,
                    name: 'Cricket',
                    icon: 'sports_cricket',
                    slug: 'cricket'
                },
                {
                    id: 9,
                    name: 'Cycling',
                    icon: 'radio_button_unchecked',
                    slug: 'cricket'
                }

            ]
        }
    ]
};