import {LeftnavActions} from "./leftnav.actions";

export function toggleDrawerMenu(value: boolean) {
    return {
        type: LeftnavActions.TOGGLE_DRAWER_MENU,
        value
    }
}

export function toggleSecondLevelNav(value: boolean) {
    return {
        type: LeftnavActions.TOGGLE_SECOND_LEVEL_NAV,
        value
    }
}

