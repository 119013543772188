import React from 'react';
import './footer.module.scss';
import InfoLinks from "../info-links/info-links";

interface Props {

}

const Footer = (props: Props) => {
    return (
        <footer>
            <InfoLinks/>
            <ul>
                <li><a href="/">Link 1</a></li>
                <li><a href="/">Link 2</a></li>
                <li><a href="/">Link 3</a></li>
                <li><a href="/">Link 4</a></li>
            </ul>
            <ul>
                <li><a href="/">Link 1</a></li>
                <li><a href="/">Link 1</a></li>
                <li><a href="/">Link 1</a></li>

            </ul>
            <ul>
                <li><a href="/">Link 1</a></li>
                <li><a href="/">Link 1</a></li>
                <li><a href="/">Link 1</a></li>
            </ul>
            <ul>
                <li><a href="/">Link 1</a></li>
            </ul>
        </footer>
    )
};
export default Footer;