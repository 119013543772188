import {LeftnavActions, LeftnavTypes} from "./leftnav.actions";
import {ILeftNav} from "store/types";

const initLeftNav: ILeftNav = {
    showSecondLevel: false,
    currMainCat: null,
    secondLevelItems: [],
    showDrawer: false
};

export function leftnavReducer(state: ILeftNav = initLeftNav, action: LeftnavTypes): ILeftNav {
    switch (action.type) {
        case LeftnavActions.TOGGLE_SECOND_LEVEL_NAV: {
            return {
                ...state,
                showSecondLevel: action.value
            }
        }
        case LeftnavActions.TOGGLE_DRAWER_MENU: {
            return {
                ...state,
                showDrawer: action.value
            }
        }
        default:
            return state;
    }
}