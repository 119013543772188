import {SportActions} from "./sport.actions";

export const getSport = () => {
    return {
        type: SportActions.GET_SPORT
    }
};

export const getSportSuccess = (payload: any) => {
    return {
        type: SportActions.GET_SPORT_SUCCESS,
        payload
    }
};