import React from 'react'
import {useTranslation} from "react-i18next";
import styles from './search.module.scss';
import {withRouter} from "react-router-dom";
import InfoLinks from "../../info-links/info-links";

interface Props {

}

const Search = (props: Props) => {
    const {t} = useTranslation();
    return (
        <div className={styles.searchContainer}>
            <div className={styles.searchInput}>
                <i className="material-icons">search</i>
                <input type="text" placeholder={t('Search for components, teams or events')}/>
            </div>
            <InfoLinks/>
        </div>
    )
};
export default withRouter(Search);