import {SportActions, SportActionUnion} from "./sport.actions";

interface ISportState {
    sport: any
}

const sportInitialState: ISportState = {
    sport: null
};

export const sportReducer = (state: ISportState = sportInitialState, action: SportActionUnion) => {
    switch (action.type) {
        case SportActions.GET_SPORT_SUCCESS: {
            return {
                ...state,
                sport: (action as any).payload
            }
        }
        default:
            return state;
    }
};
