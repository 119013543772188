import React from 'react'
import {Link} from "react-router-dom";
import styles from './info-links.module.scss';

interface Props {

}

const InfoLinks = (props: Props) => {
    return (
        <div className={styles.info_links}>
            <ul>
                <li><Link to='/chat'>Live Chat</Link></li>
                <li><Link to='/odds'>Odds</Link></li>
                <li><Link to='/help'>Help</Link></li>
                <li><Link to='/more'>More</Link></li>
            </ul>
        </div>
    )
};
export default InfoLinks;