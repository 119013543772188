import React, {Suspense, useEffect, useLayoutEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Loading from './components/loading/loading';
import TopNav from "./components/nav/topnav/topnav";
import Activity from "./components/activity/activity";
import Login from "./components/nav/auth-actions/login/login";
import LeftNav from "./components/nav/leftnav/leftnav";
import Footer from "./components/footer/footer";
import {useDispatch, useSelector} from 'react-redux';
import {initAppState} from './store/init.action.creators';
import './main.scss';
import Home from "./components/home/home";
import {IState} from "./store/types";
import {
    toggleDrawerMenu,
    toggleSecondLevelNav
} from "./components/nav/leftnav/leftnav.action.creators";
import vars from 'styles/expvars.scss';

const Feed = React.lazy(() => import('./components/feed/feed'));
const Sport = React.lazy(() => import('./components/sport/sport'));
const Casino = React.lazy(() => import('./components/casino/casino'));
const LiteRegister = React.lazy(() => {
    return import('./components/nav/auth-actions/lite-registration-page/lite-registration-page');
});
const FullRegistrationForm = React.lazy(() => {
    return import('./components/nav/auth-actions/full-registration-form/full-regform');
});

const winResBreakpoint = vars.midResolution ? vars.midResolution.slice(0, -2) : null;

const Main: React.FC = (props) => {
    const dispatch = useDispatch();
    const isHome = useSelector((state: IState) => {
        return state.home && Object.keys(state.home) && state.home.isHome;
    });
    const drawerOpen = useSelector((state: IState) => {
        return state.leftNav && Object.keys(state.leftNav) && state.leftNav.showDrawer;
    });
    const secondLevelOpen = useSelector((state: IState) => {
        return state.leftNav && Object.keys(state.leftNav) && state.leftNav.showSecondLevel;
    });
    //console.log('home', isHome);
    useEffect(() => {
        dispatch(initAppState());
    }, [dispatch]);

    useLayoutEffect(() => {
        const hideDrawerOnWinResize = () => {
            if (window.innerWidth > winResBreakpoint) {
                if (drawerOpen) {
                    dispatch(toggleDrawerMenu(false));
                    if (secondLevelOpen) {
                        dispatch(toggleSecondLevelNav(false));
                    }
                }
                if (!drawerOpen) {
                }
            }
            if (window.innerWidth <= winResBreakpoint) {
                if (secondLevelOpen && !drawerOpen) {
                    dispatch(toggleSecondLevelNav(false));
                }
            }
        };
        window.addEventListener('resize', hideDrawerOnWinResize);

        return () => {
            window.removeEventListener('resize', hideDrawerOnWinResize);
        }
    }, [dispatch, drawerOpen, secondLevelOpen]);

    return (
        <Suspense fallback={<Loading/>}>
            <Router>
                <div className={isHome ? 'container home' : 'container'}>
                    <TopNav/>
                    <LeftNav/>
                    <main className="pageContent">
                        <Switch>
                            <Route path="/sport" exact={true} component={Sport}/>}/>
                            <Route path="/casino" exact={true} component={Casino}/>}/>
                            <Route path="/feed" exact={true} component={Feed}/>
                            <Route path="/login" exact={true} component={Login}/>
                            <Route path="/register" exact={true} component={LiteRegister}/>
                            <Route path="/register/full" exact={true}
                                   component={FullRegistrationForm}/>
                            <Route path="/" exact={true} component={Home}/>
                        </Switch>
                    </main>
                    {/* ---- Feed Activity ----*/}
                    <Activity/>
                    <Footer/>
                </div>
            </Router>
        </Suspense>
    );
};

export default Main;
