export const LeftnavActions = {
    TOGGLE_DRAWER_MENU: "[LEFT NAV] Toggle Drawer Navigation",
    TOGGLE_SECOND_LEVEL_NAV: "[LEFT NAV] Toggle 2nd level nav",
};

export interface ToggleDrawer {
    type: typeof LeftnavActions.TOGGLE_DRAWER_MENU;
    value: boolean
}

export interface ToggleSecondLevelNav {
    type: typeof LeftnavActions.TOGGLE_SECOND_LEVEL_NAV;
    value: boolean
}

export type LeftnavTypes = ToggleDrawer | ToggleSecondLevelNav;