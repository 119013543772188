import {combineReducers} from 'redux';
import {InitActionUnion, InitStoreActions} from "./actions";
import {IInit, IState} from "./types";
import {homeReducer} from "../components/home/home.reducer";
import {leftnavReducer} from "../components/nav/leftnav/leftnav.reducers";

const initialState: IInit = {
    eventSlug: '',
    leftnavData: null
};

const initReducer = (state = initialState, action: InitActionUnion): IInit => {
    switch (action.type) {
        case InitStoreActions.SET_INIT_SUCCESS:
            return {
                ...state,
                leftnavData: (action as any).payload.leftnavData,
                eventSlug: (action as any).payload.eventSlug,
            };
        default:
            return state;
    }
};

export const rootReducer = (asyncReducers = {}) => {
    return combineReducers<IState>({
        init: initReducer,
        leftNav: leftnavReducer,
        home: homeReducer,
        ...asyncReducers
    });
};