import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {injectAsyncReducer} from "store/configure-store";
import {store} from "store/store";
import {getSport, getSportSuccess} from "./sport.action.creators";
import {sportReducer} from "./sport.reducer";
import styles from './sport.module.scss';
import {IState} from "store/types";
import {toggleSportPage} from "../nav/topnav/topnav.action.creators";

interface Props {

}

const Sport = (props: Props) => {
    const dispatch = useDispatch();
    const isHome = useSelector((state: IState) => state.home && state.home.isHome);
    //console.log('HOME', isHome);
    const isSportOpened = useSelector((state: IState) => {
        return state.topnav && state.topnav.toggleSport;
    });

    useEffect(() => {
        injectAsyncReducer(store, 'sport', sportReducer);
        dispatch(getSport());
        dispatch(getSportSuccess({sport: 'test sport'}));
    }, [dispatch]);

    useEffect(() => {
        const dispatchFromHome = () => {
        };
        const dispatchFromSport = () => {
            dispatch(toggleSportPage(false));
        };
        return isHome ? dispatchFromHome : dispatchFromSport;
    }, [dispatch, isHome]);

    return (
        <div
            className={isSportOpened ?
                [styles.sportContainer, styles.slideIn].join(" ") : styles.sportContainer}>
            Sport
        </div>
    )
};

export default Sport;