import {InitStoreActions} from "./actions";
import {IInit, IState} from "./types";
import {leftNav} from "../components/nav/leftnav/leftnav-data";

// Action creators
export const initAppState = () => {
    // return {
    //     type: InitStoreActions.SET_INIT
    // }
    return (dispatch: any, getState: IState) => {
        let init: IInit = {
            leftnavData: leftNav,
            eventSlug: "EventSlug"
        };

        dispatch(initAppStateSuccess(init))

    }
};
export const initAppStateSuccess = (payload: IInit) => {
    return {
        type: InitStoreActions.SET_INIT_SUCCESS,
        payload
    }

};

export const initAppStateError = (error: any) => {
    return {
        type: InitStoreActions.INIT_ERROR,
        error
    }
};


