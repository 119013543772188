export const detectMobile = (): boolean => {
    const nav = navigator.userAgent;
    return /android/i.test(nav) ||
        /ipad/i.test(nav) ||
        /iphone/i.test(nav) ||
        /ipod/i.test(nav) ||
        /blackberry/i.test(nav) ||
        /windows phone/i.test(nav) ||
        /webos/i.test(nav);
};

export const yearsRange = (start: number, end?: number): number[] => {
    const years: number[] = [];
    const currentYear = new Date().getFullYear();
    end = end || currentYear - 18;
    start = start || 1940;
    let i = start;
    while (i <= end) {
        years.push(i);
        i++;
    }

    return years;
};