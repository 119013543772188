export const HomeActions = {
    TOGGLE_IS_HOME: '[HOME] Toggle isHome'
};

export const toggleIsHome = (value: boolean) => {
    return {
        type: HomeActions.TOGGLE_IS_HOME,
        value
    }
};
