export const TopnavActions = {
    TOGGLE_SPORT: "[TOPNAV] Toggle Sport page",
    TOGGLE_CASINO: "[TOPNAV] Toggle Casino page",
    TOGGLE_HOME: "[TOPNAV] Toggle Home page",
};

export interface OpenSport {
    type: typeof TopnavActions.TOGGLE_SPORT,
    value: boolean
}

export interface OpenCasino {
    type: typeof TopnavActions.TOGGLE_CASINO,
    value: boolean
}

export interface OpenHome {
    type: typeof TopnavActions.TOGGLE_HOME,
    value: boolean
}

export type TopnavTypes = OpenSport | OpenCasino | OpenHome;