import React, {useEffect} from 'react'
import './home.scss';
import Sport from "../sport/sport";
import {useDispatch} from "react-redux";
import {toggleIsHome} from "./home.actions";
import {toggleHomePage} from "../nav/topnav/topnav.action.creators";

interface Props {

}

const Home = (props: Props) => {
    const dispatch = useDispatch();
    const isHome = true; // window.location.pathname === '/' || true; // if path is ""

    useEffect(() => {
        //console.log('home', isHome);
        dispatch(toggleIsHome(isHome));
        return () => {
            dispatch(toggleIsHome(false));
            dispatch(toggleHomePage(false));
        }
    }, [dispatch, isHome]);
    return (
        <div className="homeContainer">
            <Sport/>
        </div>
    )
};
export default Home;