import React from 'react'

interface Props {

}

const Login = (props: Props) => {
    return (
        <div>
            Login
        </div>
    )
};
export default Login;