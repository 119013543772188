import React, {MouseEvent, useState} from 'react'
import styles from './leftnav.module.scss';
import {Link, NavLink} from "react-router-dom";
import SecondLevelNav from "./second-level-nav/second-level-nav";
import {useDispatch, useSelector} from 'react-redux';
import {IState} from "store/types";
import {toggleSecondLevelNav} from "./leftnav.action.creators";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

interface Props {

}

const LeftNav = (props: Props) => {
    const dispatch = useDispatch();
    const nav = useSelector((state: IState) => {
        return state && state.init && Object.keys(state.init) && state.init.leftnavData;
    });

    const [menuCat, setMenuCat] = useState('');
    const [subMenuItems, setSubMenuItems] = useState([]);
    const slideInNav = (i: number) => {
        dispatch(toggleSecondLevelNav(true));
        if (nav && Object.keys(nav)) {
            setMenuCat(nav.subCategories[0].items[i].name);
            setSubMenuItems(nav.subCategories[0].items[i].items);
        }
    };

    const mainNavItems = nav && Object.keys(nav) && nav.mainnav.map((item: any) => (
        <li key={item.id}>
            <NavLink
                to={{pathname: `/${item.slug}`}}
                className={styles.navItem}
            >
                <ListItemIcon>
                    <i className="material-icons">
                        {item.icon}
                    </i>
                </ListItemIcon>
                <ListItemText primary={item.name}/>
            </NavLink>
        </li>
    ));

    const sportNavItems = nav && Object.keys(nav) && nav.subCategories.length
        && nav.subCategories[0].items &&
        nav.subCategories[0].items.map((item: any) => (
            <li key={item.id}>
                <NavLink
                    to="/"
                    className={styles.navItem}

                    onClick={(event: MouseEvent<HTMLAnchorElement | MouseEvent>) => {
                        event.preventDefault();
                        slideInNav(item.id - 1);
                    }}
                >
                    {item.icon ? (
                        <ListItemIcon>
                            <i className="material-icons">
                                {item.icon}
                            </i>
                        </ListItemIcon>
                    ) : (<></>)}
                    <ListItemText primary={item.name}/>
                </NavLink>
                <SecondLevelNav
                    menu={menuCat}
                    menuItems={subMenuItems}
                />
            </li>
        ));

    return (
        <div className={styles.leftNavWrapper}>
            <nav className={styles.leftnav}>
                <Link to="/" className={styles.homeLink}>
                    <i className="material-icons">
                        home
                    </i>
                    Home
                </Link>
                <ul>
                    {mainNavItems}
                </ul>
                <hr className={styles.menuDivider}/>
                <ul>
                    {sportNavItems}
                </ul>
            </nav>
        </div>


    )
};
export default LeftNav;
