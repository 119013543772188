import {TopnavActions, TopnavTypes} from "./topnav.actions";

export const toggleSportPage = (value: boolean): TopnavTypes => {
    return {
        type: TopnavActions.TOGGLE_SPORT,
        value
    }
};
export const toggleCasinoPage = (value: boolean): TopnavTypes => {
    return {
        type: TopnavActions.TOGGLE_CASINO,
        value
    }
};
export const toggleHomePage = (value: boolean): TopnavTypes => {
    return {
        type: TopnavActions.TOGGLE_HOME,
        value
    }
};