import {applyMiddleware, createStore} from 'redux';
import {rootReducer} from "./root-reducer";
import {IState} from "./types";
import {composeWithDevTools} from "redux-devtools-extension";
import thunkMiddleware from 'redux-thunk';

export const initStoreState: IState = {
    init: undefined,
    leftNav: undefined,
    home: undefined
};

export function injectAsyncReducer(store: any, name: string, asyncReducer: any) {
    if (store.asyncReducers[name]) {
        return;
    }
    store.asyncReducers[name] = asyncReducer;
    store.replaceReducer(rootReducer(store.asyncReducers));
}

export default function configureStore(initialState = initStoreState) {
    const isProduction: boolean = process.env.NODE_ENV === 'production';

    const middlewares = isProduction ?
        [thunkMiddleware] :
        [require('redux-immutable-state-invariant').default(), thunkMiddleware]; // more middleware
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer]; // more enhancers
    const composedEnhancers = isProduction ? middlewareEnhancer : composeWithDevTools(...enhancers);

    const store: Partial<IState> = createStore(rootReducer(), initialState, composedEnhancers);

    store.asyncReducers = {};

    return store;
};
