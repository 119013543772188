import {TopnavActions, TopnavTypes} from './topnav.actions';

export interface TopnavState {
    toggleSport: boolean;
    toggleCasino: boolean;
    toggleHome: boolean;
}

export const topnavInitState = {
    toggleSport: false,
    toggleCasino: false,
    toggleHome: false,
};

export const topnavReducer = (state: TopnavState = topnavInitState, action: TopnavTypes) => {
    switch (action.type) {
        case TopnavActions.TOGGLE_SPORT:
            return {
                ...state,
                toggleSport: action.value
            };
        case TopnavActions.TOGGLE_CASINO:
            return {
                ...state,
                toggleCasino: action.value
            };
        case TopnavActions.TOGGLE_HOME:
            return {
                ...state,
                toggleHome: action.value
            };
        default:
            return state;
    }
};