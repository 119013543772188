import React, {useEffect} from 'react'
import styles from './activity.module.scss';
import ActivityCard from "./activity-card/activity-card";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "store/types";
import {toggleHomePage} from "../nav/topnav/topnav.action.creators";
import {detectMobile} from "shared/common-functions";

interface Props {

}

const Activity = (props: Props) => {
    const isHomeOpened = useSelector((state: IState) => {
        return state.topnav && state.topnav.toggleHome;
    });
    const isMobile = detectMobile();
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(toggleHomePage(false));
        }
    }, [dispatch]);

    let user = 'Jonny';
    return (
        <aside
            className={isHomeOpened && isMobile ?
                [styles.feed, styles.slideIn].join(" ") : styles.feed}>
            <h1>Activity</h1>
            <ActivityCard title={`Welcome back, ${user}`} type="regular"/>
            <ActivityCard title={'NEW GAMES LAUNCHED THIS WEEK'} type="regular"/>
            <ActivityCard title={'Fury vs Joshua'} type="event"/>
        </aside>
    )
};
export default Activity;