export const SportActions = {
    GET_SPORT: '[SPORT] Get Sport',
    GET_SPORT_SUCCESS: '[SPORT] Get Sport SUCCESS',
    SPORT_ERROR: '[SPORT] ERROR'
};

export interface IGetSport {
    type: typeof SportActions.GET_SPORT
}

export interface IGetSportSuccess {
    type: typeof SportActions.GET_SPORT_SUCCESS,
    payload: any
}

export type SportActionUnion = IGetSport | IGetSportSuccess;