import React, {useEffect, useLayoutEffect, useState} from 'react';
import './second-level-nav.scss';
import {NavLink} from "react-router-dom";
import {toggleSecondLevelNav} from "../leftnav.action.creators";
import {useDispatch, useSelector} from "react-redux";
import {IState, SecondLevelNavItem} from "store/types";
import vars from 'styles/expvars.scss';

interface Props {
    menu: string;
    menuItems: SecondLevelNavItem[];
}

const winResBreakpoint = vars.midResolution ? vars.midResolution.slice(0, -2) : null;

const SecondLevelNav = (props: Props) => {
    const dispatch = useDispatch();
    const {menuItems} = props;

    const showSubMenu = useSelector((state: IState) =>
        state.leftNav && Object.keys(state.leftNav) && state.leftNav.showSecondLevel);
    const drawerOpen = useSelector((state: IState) =>
        state.leftNav && Object.keys(state.leftNav) && state.leftNav.showDrawer);

    const [initHide, setInitHide] = useState(true);
    const [navClass, setNavClass] = useState('subNav');
    useEffect(() => {
        if (showSubMenu) {
            setInitHide(false);
            setNavClass('subNav show');
        } else {
            initHide ? setNavClass('subNav') : setNavClass('subNav hide');
        }
    }, [showSubMenu, initHide]);

    useLayoutEffect(() => {
        const changeSubNavCssCLass = () => {
            if (showSubMenu) {
                if ((drawerOpen && window.innerWidth === winResBreakpoint) ||
                    (!drawerOpen && window.innerWidth < winResBreakpoint)) {
                    setNavClass('subNav'); //close drawer, no hide anim
                }
                //else if resizing > 1024, leave it as is open, show
            } else {
                //hide submenu
                if ((drawerOpen && window.innerWidth > winResBreakpoint) || !drawerOpen) {
                    setNavClass('subNav');
                }
            }
        };
        window.addEventListener('resize', changeSubNavCssCLass);
        return () => {
            window.removeEventListener('resize', changeSubNavCssCLass);
        }
    }, [dispatch, showSubMenu, drawerOpen]);


//     const distinctCategories = (item: any, list: any[]) => {
//         return list.indexOf(item) < 0 ) {
//         list.push(item)
//     }
// }

    const categories = () => {
        const cats: string[] = [];

        if (menuItems) {
            menuItems.forEach((item: SecondLevelNavItem) => {
                if (item.cat && cats.indexOf(item.cat) < 0) {
                    cats.push(item.cat)
                }
            });
        }

        return cats;
    };
    const itemCategories = categories();
    const menuCat = itemCategories.map((item: string, i: number) => {
        return (
            <div key={i}>
                <h5>{item}</h5>

                {menuItems.filter((list) => list.cat === item)
                    .map((listItem, ind: number) => {
                        return (
                            <ul key={ind}>
                                {/*<li>{listItem.name}</li>*/}
                                <li key={listItem.id}>
                                    <NavLink to={`/sport/${listItem.slug}`}>
                                        <span>{listItem.name}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        )
                    })}
            </div>
        );
    });


    const closeMenu = () => {
        dispatch(toggleSecondLevelNav(false));
        setNavClass('subNav hide');
    };

    return (
        <nav className={navClass}>
            <div className="secondLevelBack" onClick={closeMenu}> &lt; Back</div>
            {menuCat}
            {/*<ul>*/}
            {/*    {navItem}*/}
            {/*</ul>*/}

        </nav>
    );
};
export default SecondLevelNav;