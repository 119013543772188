import React from 'react'
import styles from './logo.module.scss';
import {Link, withRouter} from "react-router-dom";
import {toggleHomePage} from "../topnav/topnav.action.creators";
import {useDispatch} from "react-redux";
import {detectMobile} from "../../../shared/common-functions";
import vars from 'styles/expvars.scss';

const mobileRes = +(vars.mobileRes568 && vars.mobileRes568.slice(0, -2));

const Logo = () => {
    const dispatch = useDispatch();
    const isMobile = detectMobile();
    //console.log('mobile/home', isMobile, isHome);
    return (
        <div className={styles.logoWrapper}>
            <Link
                to='/'
                onClick={() => isMobile && window.innerWidth <= mobileRes ?
                    dispatch(toggleHomePage(true)) : null}>
                <span>
                    <i className="material-icons">whatshot</i>
                </span>
            </Link>
        </div>
    )
};
export default withRouter(Logo);