import React from 'react'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import styles from './auth-actions.module.scss';

interface Props {

}

const AuthActions = (props: Props) => {
    const {t} = useTranslation();
    return (
        <div className={styles.auth}>
            <ul>
                <li><Link to="/login">{t('Login')}</Link></li>
                <li><Link to="/register">{t('Join now')}</Link></li>
            </ul>

        </div>
    )
};

export default AuthActions;
