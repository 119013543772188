import React from 'react'
import styles from './activity-card.module.scss';

interface Props {
    title: string;
    type: string;
    //icon: string;
}

const ActivityCard = (props: Props) => {
    const {title, type} = props;
    let typeEvent = type === "event";
    return (
        <div className={styles.cardWrapper}>
            {typeEvent ? (<h3 className={styles.event}>{title}</h3>) : (<h3>{title}</h3>)}
        </div>
    )
};
export default ActivityCard;