import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import React, {useEffect} from "react";
import styles from './topnav.module.scss';
import Logo from "../logo/logo";
import AuthActions from "../auth-actions/auth-actions";
import Search from "../search/search";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AppBar from "@material-ui/core/AppBar";
import LeftNav from "../leftnav/leftnav";
import {toggleDrawerMenu} from "../leftnav/leftnav.action.creators";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "store/types";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {injectAsyncReducer} from "store/configure-store";
import {store} from "store/store";
import {topnavReducer} from "./topnav.reducers";
import {toggleCasinoPage, toggleSportPage} from "./topnav.action.creators";
import {detectMobile} from "shared/common-functions";
import vars from 'styles/expvars.scss';

const mobileRes = +(vars.mobileRes568 && vars.mobileRes568.slice(0, -2));

interface ResponsiveDrawerProps {
    container?: Element;
}

const TopNav = (props: ResponsiveDrawerProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMobile = detectMobile();
    const drawerOpen = useSelector((state: IState) => {
        return (state.leftNav && Object.keys(state.leftNav) && state.leftNav.showDrawer) || false;
    });
    //console.log('home', isHome);
    useEffect(() => {
        injectAsyncReducer(store, 'topnav', topnavReducer);
    }, [dispatch]);
    const drawer = (side = "left") => (
        <div
            className={styles.drawerLeftNavWrapper}
            role="presentation"
        >
            <LeftNav/>
        </div>
    );
    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        //dispatch(toggleDrawerMenu(!drawerOpen));
        dispatch(toggleDrawerMenu(open));
        //setDrawerLeft(open);
    };
    return (
        <>
            <AppBar position="relative" className={styles.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={toggleDrawer(true)}
                        className={styles.menuButton}
                    >
                        <i className="material-icons">menu</i>
                    </IconButton>


                    <Logo/>
                    <nav className={styles.topNav}>
                        <ul id={styles.mainNav}>
                            <li className={styles.sportLi}>
                                <NavLink
                                    to="/sport/"
                                    onClick={() => isMobile && window.innerWidth <= mobileRes ?
                                        dispatch(toggleSportPage(true)) : null}
                                >
                                    {t('sport')}
                                </NavLink>
                            </li>
                            <li className={styles.casinoLi}>
                                <NavLink
                                    to="/casino/"
                                    onClick={() => isMobile && window.innerWidth <= mobileRes ?
                                        dispatch(toggleCasinoPage(true)) : null}
                                >
                                    {t('casino')}
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <AuthActions/>

                    <Search/>
                </Toolbar>
            </AppBar>

            <SwipeableDrawer
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {drawer('left')}
            </SwipeableDrawer>
        </>
    );
};

export default TopNav;