import './styles/styles.scss';
import './i18n';
import 'whatwg-fetch';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Main from './main';
import {Provider} from "react-redux";
import {store} from './store/store';

ReactDOM.render(
    <Provider store={store as any}>
        <Main/>
    </Provider>,
    document.getElementById('root')
);

